import { useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
} from "icr-shared-components";
import { useHistory } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { RootState } from "../../reducers";
import { Action, setLoading } from "../../actions";
import {
  getZusatzsparenConfirmationData,
  ZusatzsparenConfirmationData,
  setZusatzsparenApplyData,
} from "../../actions/zusatzsparen";
import { parseDate } from "../../utils/react";
import SlateTranslator from "../SlateTranslator";
import { formatMoney } from "../../utils/currency";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";

interface Props {
  zusatzsparenConfirmationData: ZusatzsparenConfirmationData;
  getZusatzsparenConfirmationData: any;
  setZusatzsparenApplyData: any;
  planId: string;
  setLoading: any;
}

const ZusatzsparenConfirmation = ({
  zusatzsparenConfirmationData,
  getZusatzsparenConfirmationData,
  setZusatzsparenApplyData,
  planId,
  setLoading,
}: Props) => {
  const { t: translate } = useTranslation(["additionalSavingsPage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setLoading(true);
    getZusatzsparenConfirmationData(planId).then((response) =>
      setLoading(false)
    );
  }, [getZusatzsparenConfirmationData, planId]);

  const handleSubmit = async () => {
    setLoading(true);
    const response = await setZusatzsparenApplyData(planId);
    if (response.isAxiosError) {
      setDisableButton(true);
      setLoading(false);
    } else {
      history.push(
        getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGSAPPLY)
      );
      setLoading(false);
    }
  };

  return (
    <>
      <h4 className="mb-32 profile-mobile-modal-text">
        {t(
          "customize.confirmation.subtitle",
          "Sie haben folgende Anpassung gewählt"
        )}
      </h4>

      <p className="text-medium">
        {t("customize.confirmation.option.title", "Zusatzsparen Option")}
      </p>
      <h4 className="mt-1">
        {zusatzsparenConfirmationData?.additionalSavingsOption?.title}
      </h4>

      <p className="text-medium mt-4">
        {t("customize.confirmation.validFrom.title", "Gültig ab")}
      </p>
      <h4 className="mt-1">
        {parseDate(
          zusatzsparenConfirmationData?.additionalSavingsOption?.startDate
        )}
      </h4>

      <p className="text-medium mt-4">
        {t(
          "customize.confirmation.employeeContributions.title",
          "Arbeitnehmerbeiträge gem. aktuellem Lohn"
        )}
      </p>
      <h4 className="mt-1">
        {`CHF/Mt. ${formatMoney(
          zusatzsparenConfirmationData?.additionalSavingsOption?.value.toString()
        )}`}
      </h4>

      <p className="text-medium mt-32 mb-48">
        <p className="mb-24">
          {t(
            "customize.confirmation.warning.title",
            "Die Beiträge des Arbeitgebers ändern sich nicht."
          )}
        </p>
        <p>
          {t(
            "customize.confirmation.warning.subTitle",
            "Nach Abschluss erhalten Sie eine Bestätigung. Den neuen Versichertenausweis erhalten Sie im Januar. Die gewählte Option wird automatisch verlängert. Sie können diese bis jeweils den 31.10.des laufenden Jahres auf das Folgejahr anpassen."
          )}
        </p>
      </p>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(
              getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGSCUSTOMIZE)
            )
          }
        >
          {translate("buttons.previous", {
            ns: "app",
            defaultValue: "ABBRECHEN",
          })}
        </Button>

        <Button
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={handleSubmit}
          isDisabled={disableButton}
        >
          {translate("buttons.finishNow", {
            ns: "app",
            defaultValue: "JETZT ABSCHLIEßEN",
          })}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  zusatzsparenConfirmationData: state.zusatzsparen.zusatzsparenConfirmationData,
  planId: state.zusatzsparen.selectedPlan,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getZusatzsparenConfirmationData: bindActionCreators(
    getZusatzsparenConfirmationData,
    dispatch
  ),
  setZusatzsparenApplyData: bindActionCreators(
    setZusatzsparenApplyData,
    dispatch
  ),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ZusatzsparenConfirmation);
