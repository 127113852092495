import axios from "axios";
import { store } from "../store";
import { logOutLocally, setLoading } from "../actions";
import { toaster } from "./toaster";
import { getLanguageFromUrl, getTenantFromUrl } from ".";
import { RouteURLsByLanguage, DefaultRouteNames } from "../routes";

export const baseURL = process.env.REACT_APP_REST_API_URL;

export const api = axios.create({
  withCredentials: true,
  baseURL:
    baseURL ||
    "https://development.api.insurantportal.pensiondynamics.ch/jsonapi/v1/",
});

api.interceptors.request.use(
  (config) => {
    const csrfToken = (document.querySelector('meta[name="csrf-token"]') as any)
      ?.content;
    if (csrfToken) {
      config.headers["X-CSRF-TOKEN"] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    const csrfToken = response.headers["x-csrf-token"];

    if (csrfToken) {
      setCSRFToken(csrfToken);
    }

    return response;
  },
  async function (error) {
    if ([401, 500].includes(error.response?.status)) {
      const notRegisteredErrorCode = "err_3_3_2_42";
      const registrationInProgressErrorCode = "err_3_3_2_210";
      const tokenExpiredErrorCode = "err_3_3_2_4_109";
      const errorCode = error?.response?.data?.errors?.[0]?.code;

      const showPopup =
        (notRegisteredErrorCode != errorCode &&
          registrationInProgressErrorCode != errorCode) ||
        tokenExpiredErrorCode != errorCode;

      if (showPopup) {
        const errors = error?.response?.data?.errors;
        if (errors && errors.length) {
          errors.forEach((error) => {
            toaster({
              message: error.detail,
              type: "error",
            });
          });
        }
        logOutLocally();
        store.dispatch(setLoading(false));
        window.open(
          `${
            window.location.origin
          }${getTenantFromUrl()}/${getLanguageFromUrl()}${
            RouteURLsByLanguage[
              `${DefaultRouteNames.LOGIN}-${getLanguageFromUrl()}`
            ]
          }`,
          "_self"
        );
      }
    }

    if (error.response?.status === 403) {
      logOutLocally();
      store.dispatch(setLoading(false));
      window.open(
        `${window.location.origin}${getTenantFromUrl()}/de-CH${
          RouteURLsByLanguage[`${DefaultRouteNames.FORBIDDEN}-${"de-CH"}`]
        }`,
        "_self"
      );
    }

    return Promise.reject(error);
  }
);

export const setCSRFToken = (csrfToken: string) => {
  // Set CSRF token in a meta tag
  let metaTag: any = document.querySelector('meta[name="csrf-token"]');
  if (!metaTag) {
    metaTag = document.createElement("meta");
    metaTag.name = "csrf-token";
    document.head.appendChild(metaTag);
  }
  metaTag.content = csrfToken;
  sessionStorage.removeItem("csrfToken"); // Remove from session storage after setting it in meta tag
};

export const clearCSRFToken = () => {
  let metaTag: any = document.querySelector('meta[name="csrf-token"]');
  if (metaTag) {
    metaTag.remove();
  }
};

export const persistCSRFToken = () => {
  const token = (document.querySelector('meta[name="csrf-token"]') as any)
    ?.content;
  if (token) {
    sessionStorage.setItem("csrfToken", token);
  }
};
