import {
  GenericListProps,
  LanguageProps,
  LanguageSelectedProps,
  UserProfile,
} from "@icr-ui/core";
import { isEmptyArray } from "@icr-ui/utils";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentUserLanguage,
  setI18NLoading,
  setLoading,
} from "../../actions";
import { i18n } from "../../locale";
import { RootState } from "../../reducers";
import { getLanguageFromUrl } from "../../utils";

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const bearerToken = localStorage.getItem("ip_access_token");

  const initialBrowserLanguage = i18n.language;
  const [languageItems, setLanguageItems] = useState<GenericListProps[]>([]);

  const { languageItemsList, hasMultiLanguageSupport } = useSelector(
    (state: RootState) => state.app
  );

  const defaultLanguage = initialBrowserLanguage?.split("-")[0] || "DE";
  const initialSelectedLanguage = {
    label: defaultLanguage.toUpperCase(),
    value: initialBrowserLanguage,
  };
  const [selectedLanguage, setLanguage] = useState<LanguageSelectedProps>(
    initialSelectedLanguage
  );
  const getSelectedLanguage = (language: string) => {
    const newLanguage = languageItems.filter((languageItem: GenericListProps) =>
      languageItem.value?.toString().includes(language)
    )[0];
    return newLanguage;
  };

  const handleChangeLanguage = async (language: GenericListProps) => {
    dispatch(setI18NLoading(true));
    const newLanguage = getSelectedLanguage(language.value as string);
    if (newLanguage?.value) {
      setLanguage(newLanguage);
      await i18n.changeLanguage(newLanguage.value as string);
      if (bearerToken) {
        dispatch(setLoading(true));
        dispatch(changeCurrentUserLanguage(newLanguage.value as string));
      }
    }
    if (!bearerToken) dispatch(setI18NLoading(false));
  };

  useEffect(() => {
    if (languageItemsList && isEmptyArray(languageItems)) {
      const languageItems = Object.entries(languageItemsList).map(
        (info: any) => {
          const nativeName = info[0].split("-")[0].toUpperCase();
          return {
            label: nativeName,
            value: info[0],
            title: nativeName,
          };
        }
      );
      setLanguageItems(languageItems);
    }
  }, [languageItemsList, i18n.language]);

  const userLanguage = getLanguageFromUrl();
  useEffect(() => {
    if (userLanguage) {
      const languageFromMailBox = getSelectedLanguage(userLanguage);
      if (languageFromMailBox?.value) setLanguage(languageFromMailBox);
    }
  }, [userLanguage, languageItems]);

  const languageConfig: LanguageProps = useMemo(
    () => ({
      languageList: languageItems,
      selectedLanguage,
      setSelectedLanguage: (selectedLanguage: LanguageSelectedProps) => {
        handleChangeLanguage(selectedLanguage);
      },
      hiddenMobile: true,
    }),
    [languageItems, selectedLanguage]
  );

  useEffect(() => {
    dispatch(setI18NLoading(isEmptyArray(languageItems)));
  }, [languageItems]);

  return (
    hasMultiLanguageSupport && <UserProfile languageProps={languageConfig} />
  );
};

export default LanguageSelector;
