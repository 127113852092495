import { useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  AdditionalSavingsCard,
} from "icr-shared-components";
import { useHistory } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";

import { RootState } from "../../reducers";
import { Action, setLoading } from "../../actions";
import {
  getZusatzsparenCustomizeData,
  ZusatzsparenCustomizeData,
} from "../../actions/zusatzsparen";
import { SET_ZUSATZSPAREN_PLAN } from "../../constants/actions";
import SlateTranslator from "../SlateTranslator";
import { formatMoney } from "../../utils/currency";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";

interface Props {
  zusatzsparenCustomizeData: ZusatzsparenCustomizeData;
  getZusatzsparenCustomizeData: any;
  setLoading: any;
}

const ZusatzsparenCustomize = ({
  zusatzsparenCustomizeData,
  getZusatzsparenCustomizeData,
  setLoading,
}: Props) => {
  const { t: translate } = useTranslation(["additionalSavingsPage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });
  const [plan, setPlan] = useState("");
  const [error, setError] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getZusatzsparenCustomizeData().then((response) => {
      setPlan(
        response.additionalSavingsOptions.find(
          (opt) => opt.status === "AKTUELL"
        )?.id
      );
      setError(response);
      setLoading(false);
    });
  }, [getZusatzsparenCustomizeData]);

  const handleSubmit = async () => {
    dispatch({
      type: SET_ZUSATZSPAREN_PLAN,
      payload: plan,
    });
    history.push(
      getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGSCONFIRMATION)
    );
  };

  return (
    <>
      <h4 className="mb-48 profile-mobile-modal-text">
        {t("customize.customize.plans.title", "Ihre Auswahl per 01.01.2024")}
      </h4>

      {zusatzsparenCustomizeData.additionalSavingsOptions?.map(
        (item, index) => {
          return (
            <div className="mb-12">
              <AdditionalSavingsCard
                id={"" + index}
                large={true}
                title={item.title}
                status={item.status}
                disabled={item.disable}
                subtitle={item.subTitle}
                value={formatMoney(item.value.toString())}
                selected={plan === item.id}
                onClick={() => {
                  setPlan(item.id);
                }}
              />
            </div>
          );
        }
      )}

      <p className="text-medium mt-48 mb-48">
        <p className="mb-24">
          {t(
            "customize.customize.plans.subTitle",
            "Die Einstellungen für das Zusatzsparen können Sie jederzeit, jedoch spätestens bis jeweils bis 31. Oktober für das nächste Jahr anpassen. Änderungen nach dem 31. Oktober finden erst Anwendung im übernächsten Jahr."
          )}
        </p>
      </p>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(
              getRouteByLanguage(DefaultRouteNames.ADDITIONALSAVINGS)
            )
          }
        >
          {translate("buttons.previous", {
            ns: "app",
            defaultValue: "ABBRECHEN",
          })}
        </Button>

        <Button
          isDisabled={!plan || plan === "" || !error}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={handleSubmit}
        >
          {translate("buttons.adjust", { ns: "app", defaultValue: "ANPASSEN" })}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  zusatzsparenCustomizeData: state.zusatzsparen.zusatzsparenCustomizeData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getZusatzsparenCustomizeData: bindActionCreators(
    getZusatzsparenCustomizeData,
    dispatch
  ),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ZusatzsparenCustomize);
