import { NodeRendererType } from "@graphcms/rich-text-react-renderer";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";

import { ReactComponent as Arrow } from "../assets/icons/arrow-right.svg";
import { ReactComponent as ExternalLink } from "../assets/icons/external-link.svg";
import { getTenantFromUrl, navigateToSimulator } from ".";
import { simulatorCMSKeys } from "../constants";
import { i18n } from "../locale";

interface SimulationBox {
  link?: string;
  link2?: string;
}

interface GetSimulatorLinkProps {
  href?: string;
  simulationBox: SimulationBox;
}
interface RendererProps {
  simulationBox?: SimulationBox;
  history: any;
}

export const testHashVariableLink = (link: string) => {
  const regex = /^##.*##$/;
  return regex.test(link);
};

const getSimulatorLink = ({ href, simulationBox }: GetSimulatorLinkProps) => {
  if (!href || !simulationBox) return null;

  if (href === simulatorCMSKeys.additionalSavings) {
    return simulationBox.link;
  }

  if (href === simulatorCMSKeys.capitalPurchase) {
    return simulationBox.link2;
  }

  return null;
};

export const rendererHygraph = (
  { simulationBox, history }: RendererProps = null
): NodeRendererType => ({
  h1: ({ children }) => <h1 className="mb-16">{children}</h1>,
  h2: ({ children }) => <h2 className="mb-16">{children}</h2>,
  h3: ({ children }) => <h3 className="mb-16">{children}</h3>,
  h4: ({ children }) => <h4 className="mb-16">{children}</h4>,
  h5: ({ children }) => <h5 className="mb-16">{children}</h5>,
  h6: ({ children }) => <h6 className="mb-16">{children}</h6>,
  p: ({ children }) => <p className="mb-24">{children}</p>,
  ul: ({ children }) => <ul className="mb-48">{children}</ul>,
  li: ({ children }) => <li className="mb-16">{children}</li>,
  a: ({ children, openInNewTab, href, rel, ...rest }) => {
    const simulatorLink = getSimulatorLink({ href, simulationBox });

    const isInternalIcon =
      (href && href.includes("pensiondynamics")) ||
      (href && href.startsWith("/")) ||
      !!simulatorLink;

    const handleAnchorClick = () => {
      if (!!simulatorLink) {
        navigateToSimulator(simulatorLink);
        return;
      }

      if (href.startsWith("/")) {
        history.push(`${getTenantFromUrl()}/${i18n.language}${href}`);
        return;
      } else {
        window.open(
          href,
          openInNewTab ? "_blank" : "_self",
          "noopener noreferrer"
        );
      }
    };

    if (simulatorLink && !simulationBox) {
      return null;
    }

    return (
      <a onClick={handleAnchorClick} className="m-0 p-0 higraph-link">
        {children}
        {isInternalIcon && <Arrow className="ml-16" />}
      </a>
    );
  },
});
