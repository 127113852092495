import React, { Fragment, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components/dist";
import { useHistory } from "react-router";
import { RootState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action } from "../../actions";
import {
  updateProfileLoginData,
  emailConfirmation,
} from "../../actions/profile";
import { connect } from "react-redux";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../utils/useValidateFrom";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";

const PhoneVerification = ({
  confirmationToken,
  emailConfirmation,
  newEmailAddress,
}) => {
  const [emailCode, setEmailCode] = useState("");
  const [isBlurred, setIsBlurred] = useState(false);

  const history = useHistory();
  const validation = useValidateFrom("profile", formConfig, { emailCode });

  const handleSubmit = async (e) => {
    const payload = {
      token: confirmationToken,
      code: emailCode,
    };

    await emailConfirmation(payload);
    history.push(getRouteByLanguage(DefaultRouteNames.PROFILE));
  };

  return (
    <Fragment>
      <div className="profile-modal-text">
        Sie erhalten einen Bestätigungscode per Email an die neue Email-Adresse{" "}
        {newEmailAddress.substring(0, 3)}******{newEmailAddress.slice(-3)}{" "}
        <br />
        <br />
        Um den Wechsel Ihrer Email-Adresse abzuschliessen, geben Sie bitte den
        Bestätigungscode hier ein.
      </div>
      <div className="row">
        <div className="col-md-5 col-xs-12">
          <Input
            type="text"
            name="email"
            value={emailCode}
            onChange={(name, value) => setEmailCode(value)}
            placeholder="Email-Code eingeben"
            inputWrapperClass="mb-48"
            onBlur={() => setIsBlurred(true)}
            validationMessageConfig={
              (isBlurred ||
                validation?.emailCode?.validation?.type === "success") && {
                config: validation.emailCode.validation,
                visible: true,
              }
            }
          >
            Email-Code
          </Input>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(getRouteByLanguage(DefaultRouteNames.PROFILE))
          }
        >
          abbrechen
        </Button>

        <Button
          isDisabled={validation?.emailCode?.validation?.type !== "success"}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={handleSubmit}
        >
          bestätigen
        </Button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  confirmationToken: state.profile.confirmationToken,
  newEmailAddress: state.profile.newEmailAddress,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateProfileLoginData: bindActionCreators(updateProfileLoginData, dispatch),
  emailConfirmation: bindActionCreators(emailConfirmation, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PhoneVerification);
